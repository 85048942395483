<template>
  <div class="content-wrap">
    <Nav class="move-up" style="margin-right:21px"></Nav>
    <router-view  class="move-right" />
  </div>
</template>

<script>
  import Nav from '../component/Nav'
  export default {
    components: {
      Nav
    }
  }
</script>

<style lang="less" scoped>
  .content-wrap {
    margin: 0 auto;
    width: 1200px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items:flex-start;

    .move-right {
      margin-top:21px;
      box-sizing: border-box;
      flex-grow: 0;
      width: 944px;
    }
  }
</style>


