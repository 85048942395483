var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-wrap" },
    [
      _c("Nav", {
        staticClass: "move-up",
        staticStyle: { "margin-right": "21px" },
      }),
      _c("router-view", { staticClass: "move-right" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }